import React, { Component } from 'react';
import './ScoreCard.css';
import { Accordion, Card, Button, Form, Col, Image } from 'react-bootstrap'
// import CommonService from '../../commonService/CommonService';
import EndpointConstant from './../../constants/EndpointConstant';
import utils from './../../utils';
import PropTypes from 'prop-types';
import RoleConstant from '../../constants/RoleConstant';
import MultiSelect from "@khanacademy/react-multi-select";
class ScoreCardSearch extends Component {

  constructor(props) {
    super(props);
    this.state = {
      iconName: 'icon-drop-up',
      subcaseNumber: '',
      channel: '',
      attachmentIncluded: '',
      QMAssociate: '',
      qualityMonitorQCBy: '',
      amgenQCBy: '',
      selected: [],
      dataSet: [],
      sampleSet: this.props.sampleName,
      questionStatus: '',
      scorecardList: [],
    }
    this.iconChange = this.iconChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleOnChange = this.handleOnChange.bind(this);
    this.handleOnClear = this.handleOnClear.bind(this);
    this.filterScorecard = this.filterScorecard.bind(this);
  }
  componentDidUpdate(prevProps) {
    if (prevProps.sampleName !== this.props.sampleName) {
      this.setState({
        subcaseNumber: '',
        dataSet:'',
        channel: '',
        attachmentIncluded: '',
        QMAssociate: '',
        qualityMonitorQCBy: '',
        amgenQCBy: '',
      });
    }
  }

  getDataSet() {
    let data = {};
    let self = this;
    utils.makeRequest(this, EndpointConstant.GET_DATASET_LIST, data, function (result) {
      // console.log("get dataset", result);
      self.setState({
        dataSet: result.datasets
      })
    });
  }

  componentDidMount() {
    utils.getSessionItem("role") !== 'Reviewer' && this.getDataSet();
  }

  iconChange() {
    if (this.state.iconName === 'icon-drop-up') {
      this.setState({ iconName: 'icon-drop-down' })
    } else {
      this.setState({ iconName: 'icon-drop-up' })
    }
  }
  handleOnChange(event) {
    //console.log("onchange", event);
    const { name, value } = event.target;
    this.setState({ [name]: value });
  }
  handleOnClear() {
    let self = this;
    let { sampleName } = this.props;
    self.setState({
      // iconName: 'fa fa-caret-up iconstyles-case',
      subcaseNumber: '',
      selected: [], 
      datasetName: [],
      channel: '',
      attachmentIncluded: '',
      QMAssociate: '',
      qualityMonitorQCBy: '',
      amgenQCBy: '',
      scorecardList: [],
      questionStatus: '',
    }, () => {
      if (sampleName) {
        // self.props.clearSampling(true);
      } else {
        self.props.clearState();
        self.props.getScoreCardList();
        if(utils.getSessionItem("role")===RoleConstant.QualityMonitor){
        self.props.getScoreCardList('stage1');
        }
      }
    })
  }
  handleSearch(event) {
    event.preventDefault();
    //console.log("handle search", this.state);
    // console.log("Ref" + this.props.handleClear);
    this.setState({scorecardList: []});
    let { sampleName } = this.props;
    if (!sampleName) {
      // this.props.clearSampling(false);
      sampleName = false;
    }
    let self = this
    let data = {}
    if (this.state.subcaseNumber !== '') {
      data.subcaseNumber = this.state.subcaseNumber
    }
   
    if (this.state.channel.length !== 0) {
      data.channel = this.state.channel
    }
    if (this.state.attachmentIncluded !== '') {
      data.attachmentIncluded = this.state.attachmentIncluded
    }
    if (this.state.QMAssociate !== '') {
      data.QMAssociate = this.state.QMAssociate
    }
    if (this.state.qualityMonitorQCBy !== '') {
      data.qualityMonitorQCBy = this.state.qualityMonitorQCBy
    }
    if (this.state.amgenQCBy !== '') {
      data.amgenQCBy = this.state.amgenQCBy
    }
    if (this.state.questionStatus !== '') {
      data.questionStatus = this.state.questionStatus
    }
  
   const datasetCount = this.state.selected.length;
   if (this.state.selected.length !== 0 && datasetCount > 100) {
    let startIndex = 0
    let endIndex = 100;
    const count = Math.round(datasetCount/100);
    const selectedDataSet = this.state.selected;
    this.props.clearState();
    for (let i=0;i<count;i++) {
      data.datasetId = selectedDataSet.slice(startIndex, endIndex);
      this.filterScorecard(data, true);
      if (endIndex+100 < datasetCount) {
        endIndex+=100;
        startIndex+=100;
      } else {
        endIndex = datasetCount;
        startIndex+=100;
      }
    }
   }else if(this.state.selected.length !== 0){
    data.datasetId = this.state.selected;
    this.filterScorecard(data);
   } else {
    this.filterScorecard(data);
   }
  //  if(datasetCount > 100){
    
  //  }else{
  //   this.filterScorecard(self, data);
  //  }
  //  if(datasetCount > 100){
  //   const count = Math.round(datasetCount/2);
  //   const selectedDataSet = this.state.selected;
  //   data.datasetId = selectedDataSet.slice(count,datasetCount);
  //   this.filterScorecard(self, data, true);
  //  }
   
  }

  filterScorecard(data,isSecondCall){
 // data.sampleName = sampleName;
    let self = this
    utils.makeRequest(self, EndpointConstant.FILTER_SCORECARD, data, function (result) {
    let scorecardList ;
    if(isSecondCall){
    scorecardList = self.state.scorecardList.concat(result.scorecards);
    }else{
    scorecardList = result.scorecards;
    }
    // console.log(scorecardList);
    // self.loading = false;
    self.setState({
    scorecardList: scorecardList
    }, () => { 
    self.props.getSearchResult(scorecardList);
    });

    })
  }

  compareDataset(a, b) {
    const valueA = a.label.toUpperCase();
    const valueB = b.label.toUpperCase();

    let comparison = 0;
    if (valueA > valueB) {
      comparison = 1;
    } else if (valueA < valueB) {
      comparison = -1;
    }
    return comparison;
  } 
  customFilter = (options, filter) => {
    const optionIncludesText = (option) => {
        const label = option.label.replace(/ /g, "") || "";
        const searchData = filter.toLowerCase().replace(/ /g, "") || "";
        return label.toLowerCase().startsWith(searchData);
    };
    return options.filter(optionIncludesText);
  };

  render() {
    const siteinfo = JSON.parse(utils.getSessionItem("SiteInfo"));
    const { subcaseNumber, channel, QMAssociate, amgenQCBy, questionStatus } = this.state
    // const ifrOptions = CommonService.ifrType.map((status, i) => {
    //   return (
    //     <option key={i} value={status.value}>{status.value}</option>
    //   )
    // })
    // const channelOptions = siteinfo.Channel.map((channel, i) => {
    //   return (
    //     <option key={i} value={channel}>{channel}</option>
    //   )
    // });
    const channelOptions = [];
    siteinfo.Channel.map((channel) => {
    //  console.log("dataset", channel)
      return channelOptions.push({ id: channel, label: channel && channel, value: channel });
    });
    let { selected, dataSet } = this.state;
    const options = [];
    dataSet = dataSet && dataSet.length ? dataSet: this.props.datasets;
    if (dataSet) {
      dataSet.forEach((dataset) => {
      //  console.log("dataset", dataset)
        if(dataset.status==='Complete'){
        return options.push({ id: dataset.id, label: dataset.datasetName && dataset.datasetName.split('.')[0], value: dataset.id });
      }
      });
    }
    options.sort(this.compareDataset);
    return (
      <Accordion defaultActiveKey="0" className='cardPadding searchScorecard'>
        <Card >
          <Accordion.Toggle as={Card.Header} eventKey="0" onClick={this.iconChange} className='cardBodyStyle'>
            <span className="search-title">SEARCH SCORE CARD</span><Image src="/Images/ico-drop-up.png" className={this.state.iconName}></Image>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="0">
            <Card.Body className='cardBodyStyle'>
              <Form onSubmit={this.handleSearch} className="searchForm">
                <Form.Row className='mb-2'>
                  <Col>
                    <Form.Label>Sub-Case Number</Form.Label>
                    <Form.Control type='text' name='subcaseNumber' value={subcaseNumber} onChange={this.handleOnChange} />
                  </Col>

                  <Col>
                    <Form.Label>Dataset</Form.Label>
                    <MultiSelect
                      options={options}
                      selected={selected}
                      disableSearch={false}
                      hasSelectAll={true}
                      filterOptions={this.customFilter}
                      onSelectedChanged={selected => {
                        // console.log("selected values", selected);
                        this.setState({ selected });
                        return null;
                      }
                      }
                      overrideStrings={{
                        selectSomeItems: "--Select--",
                        allItemsAreSelected: "All are Selected",
                        selectAll: "Select All"
                      }}
                    />
                    {/* </Form.Group> */}
                  </Col>
                  <Col>
                    <Form.Label>Channel</Form.Label>
                    {/* <Form.Control as='select' name='channel' value={channel} onChange={this.handleOnChange}>
                      <option value=''>--Select--</option>
                      {channelOptions}
                    </Form.Control> */}
                    <MultiSelect
                      options={channelOptions}
                      selected={channel}
                      disableSearch={false}
                      hasSelectAll={true}
                      filterOptions={this.customFilter}
                      onSelectedChanged={selected => {
                        // console.log("selected values", selected);
                        this.setState({ channel:selected });
                        return null;
                      }
                      }
                      overrideStrings={{
                        selectSomeItems: "--Select--",
                        allItemsAreSelected: "All are Selected",
                        selectAll: "Select All"
                      }}
                    />
                  </Col>
                  {/* <Col>
                    <Form.Label>Attachment Included?</Form.Label>
                    <Form.Check
                      custom inline
                      label="Yes"
                      type='radio'
                      id='Yes'
                      name="attachmentIncluded"
                      value='Yes'
                      checked={this.state.attachmentIncluded === 'Yes'}
                      onChange={this.handleOnChange}
                      className="attachment-included"
                    />
                    <Form.Check
                      custom inline
                      label="No"
                      type='radio'
                      name="attachmentIncluded"
                      id='No'
                      value='No'
                      checked={this.state.attachmentIncluded === 'No'}
                      onChange={this.handleOnChange}
                      className="attachment-included"
                    />
                  </Col> */}
                  <Col>
                    <Form.Label>Question status</Form.Label>
                     <Form.Control as='select' name='questionStatus' value={questionStatus} onChange={this.handleOnChange}> 
                        <option value='' hidden disabled>--Select--</option>
                        <option value='pass'>Pass</option>
                        <option value='fail'>Fail</option>
                        <option value='N/A'>N/A</option>
                      </Form.Control>
                  </Col>
                  <Col>
                    <Form.Label>QM Associate</Form.Label>
                    <Form.Control type='text' name='QMAssociate' value={QMAssociate} onChange={this.handleOnChange} />
                  </Col>
                  {/* <Col>
                    <Form.Label>Quality Monitor QC By</Form.Label>
                    <Form.Control type='text' name='qualityMonitorQCBy' value={qualityMonitorQCBy} onChange={this.handleOnChange} />
                  </Col> */}
                  <Col>
                    <Form.Label>Amgen QC By</Form.Label>
                    <Form.Control type='text' name='amgenQCBy' value={amgenQCBy} onChange={this.handleOnChange} />
                  </Col>
                  </Form.Row>
                  <Button variant="secondary" type="submit" className='scorecardSearch button-color '>Search</Button>
                  <Button variant="secondary" className='clear' onClick={this.handleOnClear}>Clear</Button>
                  
              </Form></Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    )
  }
}

ScoreCardSearch.propTypes = {
  getSearchResult: PropTypes.func,
  sampleName: PropTypes.string,
  clearSampling: PropTypes.func,
}

export default ScoreCardSearch;
